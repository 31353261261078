import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  feedback() {
    document.getElementById("feed")?.classList.remove('mode-none');
    document.getElementById('feedback')?.classList.remove('mod-none');
    setTimeout(() => {document.getElementById("feed")?.classList.remove('null-opacity')}, 0);
  }
  
}
