import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from "rxjs";
import { Visits } from "../models/Visits";

@Injectable()
export class VisitsService {
  path: string = '/api/visit/';

  constructor(private http: HttpClient) {
  }

  public visit() {
    return this.http.post(this.path, {});
  }

  public getVisits(): Observable<Visits[]> {
    return this.http.get<Visits[]>(this.path);
  }

}