import { Component, OnInit } from '@angular/core';
import { CookieService } from 'src/app/service/cookie.service';
import { VisitsService } from 'src/app/service/visits.service';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.css']
})
export class MainComponent implements OnInit {

  constructor(private cookieService: CookieService, private visitsService: VisitsService) { }

  mods = [1, 1, 1, 1, 1]

  ngOnInit(): void {
    if (this.cookieService.getCookie('visit') != 'true') {
      this.cookieService.setCookie('visit', 'true', 1, '/');
      this.visitsService.visit().subscribe();
    }
  }

  open(pos: number) {
    if (this.mods[pos - 1] == 0) {
      document.getElementById(`mod-about-${pos}`)?.classList.remove('mod-about-close')
      document.getElementById(`mod-${pos}`)?.classList.add('mod-close');
      
      setTimeout(() => {document.getElementById(`mod-${pos}`)?.classList.add('mod-none')}, 200)

      this.mods[pos - 1] = 1
    } else {
      setTimeout(() => {document.getElementById(`mod-${pos}`)?.classList.remove('mod-close')}, 0)
      document.getElementById(`mod-${pos}`)?.classList.remove('mod-none')
      document.getElementById(`mod-about-${pos}`)?.classList.add('mod-about-close')
      
      this.mods[pos - 1] = 0
    }
  }

  feedback() {
    document.getElementById("feed")?.classList.remove('mode-none');
    document.getElementById('feedback')?.classList.remove('mod-none');
    setTimeout(() => {document.getElementById("feed")?.classList.remove('null-opacity')}, 0);
  }

}
