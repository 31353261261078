import { Component, OnInit } from '@angular/core';
import emailjs, { EmailJSResponseStatus } from '@emailjs/browser';

@Component({
  selector: 'app-feedback',
  templateUrl: './feedback.component.html',
  styleUrls: ["../../../bootstrap.min.css", './feedback.component.css']
})
export class FeedbackComponent implements OnInit {
  name: string = "";
  company: string = "";
  phone: string = "";
  email: string = "";
  text: string = "";

  constructor() { }

  ngOnInit(): void {
  
  }

  close(): void {
    document.getElementById("feed")?.classList.add('null-opacity')

    setTimeout(() => {
      document.getElementById('feedback')?.classList.remove('mod-none');
      document.getElementById("feed")?.classList.add('mode-none');
    }, 300);

  }

  send(): void {
    var data = {
      'name': this.name == '' ? 'Аноним' : this.name, 
      'company': this.company == '' ? '' : 'из компании ' + this.company, 
      'phone': this.phone, 
      'email': this.email == '' ? 'Не указано' : this.email, 
      'text': this.text == '' ? 'Сообщение пусто' : this.text
    }

    this.close();

    emailjs.send('service_ksmev', 'template_ksmev', data, 'Vn_wdXaHtij6GbnWf')
      .then((result: EmailJSResponseStatus) => {
        this.close();
        alert("Успешно отправлено!")
      }, (error) => {
        alert("Ошибка при отправке!")
      });
  }

}
