import { Component, OnInit } from '@angular/core';
import { right } from '@popperjs/core';
import { max } from 'rxjs';
import { Visits } from 'src/app/models/Visits';
import { CookieService } from 'src/app/service/cookie.service';
import { VisitsService } from 'src/app/service/visits.service';

@Component({
  selector: 'app-visits',
  templateUrl: './visits.component.html',
  styleUrls: ['./visits.component.css']
})
export class VisitsComponent {
  visits: Visits[] = [];
  months: Array<number> = [];

  isAdmin: boolean = false;
  isMonths: boolean = true;
  password: string = "";

  maxByMonth: number = 0;
  maxByDay: number = 0;
  selectedMonth: number = 0;

  constructor(private visitsService: VisitsService, private cookieService: CookieService) { 
    visitsService.getVisits().subscribe(
      data => {
        this.visits = data;
        
        for (let month = 0; month < 12; ++month) {
          this.months.push(0);
          
          for (let day = 0; day < this.visits[month].visits.length; ++day) {
            this.months[month] += this.visits[month].visits[day];
          }

          this.maxByMonth = Math.max(this.maxByMonth, this.months[month]);
        }
      }
    )

    if (cookieService.getCookie('admin') == 'true') {
      this.isAdmin = true;
    }
    
    this.months.fill(0, 0, 12);
  }

  logIn() {
    if (this.password == "88WtyCdb") { 
      this.isAdmin = true;
      this.cookieService.setCookie('admin', 'true', 7);
    } else {
      let btn = document.getElementById('btn');
      btn?.classList.add('right');
      setTimeout(() => {
        btn?.classList.remove(right)
      }, 300);
    }
  }

  getVisitsPerDay(month: number) {
    this.isMonths = false;
    this.selectedMonth = month;

    for (let visit of this.visits[month].visits) {
      this.maxByDay = Math.max(this.maxByDay, visit);
    }
  }

  close() {
    this.isMonths = true;
  }

}
