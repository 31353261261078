import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-gear',
  templateUrl: './gear.component.html',
  styleUrls: ['./gear.component.css']
})
export class GearComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    var gear = window.location.href.split("#")[1]
    if (gear != undefined) {
      this.open(parseInt(gear));
    }
  }

  gears = [1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1]

  open(pos: number) {
    if (this.gears[pos - 1] == 0) {
      document.getElementById(`gear-btn-${pos}`)?.classList.add('gear-header-open')
      document.getElementById(`gear-${pos}`)?.classList.add('gear-close');
      
      setTimeout(() => {document.getElementById(`gear-${pos}`)?.classList.add('gear-none')}, 200)

      this.gears[pos - 1] = 1
    } else {
      setTimeout(() => {document.getElementById(`gear-${pos}`)?.classList.remove('gear-close')}, 0)
      document.getElementById(`gear-${pos}`)?.classList.remove('gear-none')
      document.getElementById(`gear-btn-${pos}`)?.classList.remove('gear-header-open')
      
      this.gears[pos - 1] = 0
    }
  }

}
