<div class="header">
    <div class="content">
        <div class="logo"></div>
        <div class="menu">
            <a href="/#trust" class="menu-item">Нам доверяют</a>
            <a href="/#business" class="menu-item">Для бизнеса</a>
            <a href="/#development" class="menu-item">Для разработки</a>
            <a href="/#mechanism" class="menu-item">Механизмы работы</a>
            <a href="/#about-us" class="menu-item">О нас</a>
        </div>
        <div class="header-btn" (click)="feedback()">Запросить демонстрацию</div>
    </div>
</div>