<app-feedback id="feedback" class="mod-none"></app-feedback>

<div class="content">
    <app-header></app-header>

    <div class="gear">
        <div class="gear-h"><a href="/" class="gear-back">Главная/</a><span style="font-weight: 450;">Механизмы работы</span></div>
        <div class="gear-header">Механизмы работы</div>
        <!-- <div class="gear-doc" (click)="downloadTech()">Техническая документация</div> -->

        <a name="1"></a>
        <div class="gear-block">
            <div class="gear-block-header" (click)="open(1)">
                <div class="gear-header-icon">
                    <svg xmlns="https://www.w3.org/2000/svg" width="1.7vw" height="1.7vw" viewBox="0 0 32 32" fill="none">
                        <path d="M6.66667 14.6665L8.66667 8.6665H23.3333L25.3333 14.6665M23.3333 21.3332C22.8029 21.3332 22.2942 21.1225 21.9191 20.7474C21.544 20.3723 21.3333 19.8636 21.3333 19.3332C21.3333 18.8027 21.544 18.294 21.9191 17.919C22.2942 17.5439 22.8029 17.3332 23.3333 17.3332C23.8638 17.3332 24.3725 17.5439 24.7475 17.919C25.1226 18.294 25.3333 18.8027 25.3333 19.3332C25.3333 19.8636 25.1226 20.3723 24.7475 20.7474C24.3725 21.1225 23.8638 21.3332 23.3333 21.3332ZM8.66667 21.3332C8.13623 21.3332 7.62753 21.1225 7.25245 20.7474C6.87738 20.3723 6.66667 19.8636 6.66667 19.3332C6.66667 18.8027 6.87738 18.294 7.25245 17.919C7.62753 17.5439 8.13623 17.3332 8.66667 17.3332C9.1971 17.3332 9.70581 17.5439 10.0809 17.919C10.456 18.294 10.6667 18.8027 10.6667 19.3332C10.6667 19.8636 10.456 20.3723 10.0809 20.7474C9.70581 21.1225 9.1971 21.3332 8.66667 21.3332ZM25.2267 7.99984C24.96 7.2265 24.2133 6.6665 23.3333 6.6665H8.66667C7.78667 6.6665 7.04 7.2265 6.77333 7.99984L4 15.9998V26.6665C4 27.0201 4.14048 27.3593 4.39052 27.6093C4.64057 27.8594 4.97971 27.9998 5.33333 27.9998H6.66667C7.02029 27.9998 7.35943 27.8594 7.60948 27.6093C7.85952 27.3593 8 27.0201 8 26.6665V25.3332H24V26.6665C24 27.0201 24.1405 27.3593 24.3905 27.6093C24.6406 27.8594 24.9797 27.9998 25.3333 27.9998H26.6667C27.0203 27.9998 27.3594 27.8594 27.6095 27.6093C27.8595 27.3593 28 27.0201 28 26.6665V15.9998L25.2267 7.99984Z" fill="black"/>
                    </svg>
                </div>
                <div class="gear-header-text">Адаптивные технологии аутентификации и авторизации</div>
                <div class="gear-header-close gear-header-open" id="gear-btn-1">
                    <svg xmlns="https://www.w3.org/2000/svg" width="1.7vw" height="1.7vw" viewBox="0 0 32 32" fill="none">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M23.0547 9.85444C23.3057 9.60339 23.3057 9.19636 23.0547 8.94531C22.8036 8.69426 22.3966 8.69426 22.1455 8.94531L16.0005 15.0904L9.85592 8.9458C9.60486 8.69475 9.19783 8.69475 8.94678 8.9458C8.69573 9.19685 8.69573 9.60389 8.94678 9.85494L15.0913 15.9995L8.94622 22.1446C8.69517 22.3957 8.69517 22.8027 8.94622 23.0538C9.19727 23.3048 9.60431 23.3048 9.85536 23.0538L16.0005 16.9086L22.1461 23.0543C22.3972 23.3053 22.8042 23.3053 23.0552 23.0543C23.3063 22.8032 23.3063 22.3962 23.0552 22.1451L16.9096 15.9995L23.0547 9.85444Z" fill="#257379"/>
                    </svg>
                </div>
            </div>
            <div class="gear-content gear-close gear-none" id="gear-1">
                <div class="gear-text">Большинство запросов в наши сервисы требуют аутентификации и авторизации. <br>
                    Аутентификация проходит по уже ставшему привычным для всех протоколу OAuth2. <br> Роль авторизационного сервера выполняет Keycloak, реализующий всю необходимую функциональность для управления пользователями, интеграцией с другими провайдерами идентификации (identity provider), такими как ЕСИА или соцсети, и с поддержкой федеративности (user federation), например LDAP. Содержит возможности по отслеживанию, сбору событий безопасности (успешные и неуспешные события входа, logout и другие). Подобная реализация позволяет минимальными настройками интегрироваться с существующими корпоративными системами идентификации и авторизации.
                                        <br> <br>
                                        В целях соответствия SRP (single responsibility principle) авторизация проводится в Шлюзе (gateway-api), а непосредственно программная часть уже пользуется тем, что все проверки выполнены ранее. <br> Благодаря этому исключаются многократные запросы и проверки в авторизационный сервер и упрощается разработка и тестирование без снижения безопасности.

                </div>
                <object width="60%" data="/assets/svg/Autentific_i_avtorizazia.svg"></object>
            </div>
        </div>

        <a name="2"></a>
        <div class="gear-block">
            <div class="gear-block-header" (click)="open(2)">
                <div class="gear-header-icon">
                    <svg xmlns="https://www.w3.org/2000/svg" width="1.7vw" height="1.7vw" viewBox="0 0 32 32" fill="none">
                        <path d="M26.6666 4H5.33329C3.86663 4 2.66663 5.2 2.66663 6.66667V21.3333C2.66663 22.8 3.86663 24 5.33329 24H9.33329L7.99996 25.3333V28H24V25.3333L22.6666 24H26.6666C28.1333 24 29.3333 22.8 29.3333 21.3333V6.66667C29.3333 5.2 28.1333 4 26.6666 4ZM26.6666 21.3333H5.33329V6.66667H26.6666V21.3333Z" fill="black"/>
                    </svg>
                </div>
                <div class="gear-header-text">Отказоустойчивость и гарантированность доставки</div>
                <div class="gear-header-close gear-header-open" id="gear-btn-2">
                    <svg xmlns="https://www.w3.org/2000/svg" width="1.7vw" height="1.7vw" viewBox="0 0 32 32" fill="none">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M23.0547 9.85444C23.3057 9.60339 23.3057 9.19636 23.0547 8.94531C22.8036 8.69426 22.3966 8.69426 22.1455 8.94531L16.0005 15.0904L9.85592 8.9458C9.60486 8.69475 9.19783 8.69475 8.94678 8.9458C8.69573 9.19685 8.69573 9.60389 8.94678 9.85494L15.0913 15.9995L8.94622 22.1446C8.69517 22.3957 8.69517 22.8027 8.94622 23.0538C9.19727 23.3048 9.60431 23.3048 9.85536 23.0538L16.0005 16.9086L22.1461 23.0543C22.3972 23.3053 22.8042 23.3053 23.0552 23.0543C23.3063 22.8032 23.3063 22.3962 23.0552 22.1451L16.9096 15.9995L23.0547 9.85444Z" fill="#257379"/>
                    </svg>
                </div>
            </div>
            <div class="gear-content gear-close gear-none" id="gear-2">
                <div class="gear-text">
                    Для обеспечения отказоустойчивости, транзакционности и защищённости при получении нового запроса выполняется ряд проверок, производится дедупликация данных, что отражено на схеме, и сохраняется при помощи Apache Kafka для обеспечения гарантированности доставки и ускорения ответа отправителю. Дальнейшая обработка произойдёт асинхронно.
                </div>
               <object width="65%" data="/assets/svg/Otkazoustoichivost.svg"></object>
            </div>
        </div>

        <a name="3"></a>
        <div class="gear-block">
            <div class="gear-block-header" (click)="open(3)">
                <div class="gear-header-icon">
                    <svg xmlns="https://www.w3.org/2000/svg" width="1.7vw" height="1.7vw" viewBox="0 0 32 32" fill="none">
                        <path d="M22.6667 5.33341V13.3334L20 10.6667L17.3333 13.3334V5.33341H12V26.6667H25.3333V5.33341H22.6667ZM4 9.33341V6.66675H6.66667V5.33341C6.66667 4.62617 6.94762 3.94789 7.44772 3.4478C7.94781 2.9477 8.62609 2.66675 9.33333 2.66675H25.3333C26.7333 2.66675 28 3.93341 28 5.33341V26.6667C28 28.0667 26.7333 29.3334 25.3333 29.3334H9.33333C7.93333 29.3334 6.66667 28.0667 6.66667 26.6667V25.3334H4V22.6667H6.66667V17.3334H4V14.6667H6.66667V9.33341H4ZM6.66667 6.66675V9.33341H9.33333V6.66675H6.66667ZM6.66667 25.3334H9.33333V22.6667H6.66667V25.3334ZM6.66667 17.3334H9.33333V14.6667H6.66667V17.3334Z" fill="black"/>
                    </svg>
                </div>
                <div class="gear-header-text">Повышение работоспособности и уменьшение нагрузки на аппаратную часть</div>
                <div class="gear-header-close gear-header-open" id="gear-btn-3">
                    <svg xmlns="https://www.w3.org/2000/svg" width="1.7vw" height="1.7vw" viewBox="0 0 32 32" fill="none">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M23.0547 9.85444C23.3057 9.60339 23.3057 9.19636 23.0547 8.94531C22.8036 8.69426 22.3966 8.69426 22.1455 8.94531L16.0005 15.0904L9.85592 8.9458C9.60486 8.69475 9.19783 8.69475 8.94678 8.9458C8.69573 9.19685 8.69573 9.60389 8.94678 9.85494L15.0913 15.9995L8.94622 22.1446C8.69517 22.3957 8.69517 22.8027 8.94622 23.0538C9.19727 23.3048 9.60431 23.3048 9.85536 23.0538L16.0005 16.9086L22.1461 23.0543C22.3972 23.3053 22.8042 23.3053 23.0552 23.0543C23.3063 22.8032 23.3063 22.3962 23.0552 22.1451L16.9096 15.9995L23.0547 9.85444Z" fill="#257379"/>
                    </svg>
                </div>
            </div>
            <div class="gear-content gear-close gear-none" id="gear-3">
                <div class="gear-text">            
                    Для регистрации и мониторинга запроса по разным сущностям, сообщение о запросе читают из Apache Kafka и после дедупликации сохраняют в базе данных его настройки, статус и состояние. Далее отправляют в следующий этап маршрута запроса. <br><br>
                    Данный механизм позволяет разгрузить ИС, участвующую во взаимодействии, обеспечить повышение скорости взаимодействия между системами, и снизить нагрузку на аппаратную часть.
                </div>
                <object width="70%" data="/assets/svg/Povish_rabotosposobnosti.svg"></object>
            </div>
        </div>

        <a name="4"></a>
        <div class="gear-block">
            <div class="gear-block-header" (click)="open(4)">
                <div class="gear-header-icon">
                    <svg xmlns="https://www.w3.org/2000/svg" width="1.7vw" height="1.7vw" viewBox="0 0 32 32" fill="none">
                        <path d="M22.121 9.87897C21.5584 9.31656 20.7955 9.00061 20 9.00061C19.2045 9.00061 18.4416 9.31656 17.879 9.87897L17.414 10.343L18.828 11.757L19.293 11.293C19.4816 11.1108 19.7342 11.01 19.9964 11.0123C20.2586 11.0146 20.5094 11.1197 20.6948 11.3052C20.8802 11.4906 20.9854 11.7414 20.9877 12.0036C20.99 12.2658 20.8892 12.5184 20.707 12.707L17.414 16L18.828 17.414L22.121 14.121C22.6834 13.5584 22.9994 12.7955 22.9994 12C22.9994 11.2045 22.6834 10.4416 22.121 9.87897ZM12.707 20.707C12.5184 20.8891 12.2658 20.9899 12.0036 20.9876C11.7414 20.9854 11.4906 20.8802 11.3052 20.6948C11.1198 20.5094 11.0146 20.2586 11.0123 19.9964C11.01 19.7342 11.1108 19.4816 11.293 19.293L14.586 16L13.172 14.586L9.879 17.879C9.59247 18.1557 9.36392 18.4867 9.2067 18.8528C9.04947 19.2188 8.96671 19.6124 8.96325 20.0108C8.95979 20.4091 9.03569 20.8041 9.18653 21.1728C9.33738 21.5415 9.56014 21.8765 9.84181 22.1582C10.1235 22.4398 10.4584 22.6626 10.8271 22.8134C11.1958 22.9643 11.5909 23.0402 11.9892 23.0367C12.3875 23.0333 12.7812 22.9505 13.1472 22.7933C13.5132 22.636 13.8443 22.4075 14.121 22.121L14.586 21.657L13.172 20.243L12.707 20.707ZM14.121 9.87997C13.5529 9.34674 12.7996 9.05545 12.0206 9.06773C11.2415 9.08001 10.4978 9.39491 9.9468 9.94579C9.39579 10.4967 9.08072 11.2403 9.06825 12.0194C9.05579 12.7984 9.3469 13.5517 9.88 14.12L10.344 14.585L11.758 13.171L11.294 12.706C11.1118 12.5174 11.011 12.2648 11.0133 12.0026C11.0156 11.7404 11.1208 11.4896 11.3062 11.3042C11.4916 11.1187 11.7424 11.0136 12.0046 11.0113C12.2668 11.009 12.5194 11.1098 12.708 11.292L16 14.586L17.414 13.172L14.121 9.87997Z" fill="black"/>
                        <path d="M22.1211 17.8789L21.6571 17.4139L20.2431 18.8279L20.7071 19.2929C20.8892 19.4815 20.99 19.7341 20.9877 19.9963C20.9855 20.2585 20.8803 20.5094 20.6949 20.6948C20.5095 20.8802 20.2587 20.9853 19.9965 20.9876C19.7343 20.9899 19.4817 20.8891 19.2931 20.7069L16.0001 17.4139L14.5861 18.8279L17.8791 22.1209C18.4449 22.6674 19.2027 22.9698 19.9893 22.963C20.7759 22.9561 21.5283 22.6406 22.0845 22.0844C22.6407 21.5282 22.9562 20.7757 22.9631 19.9891C22.9699 19.2026 22.6675 18.4447 22.1211 17.8789Z" fill="black"/>
                        <path d="M16 31.0001C15.8229 31.0002 15.649 30.9533 15.496 30.8641L3.496 23.8641C3.34502 23.776 3.21978 23.6498 3.13275 23.4982C3.04572 23.3466 2.99995 23.1749 3 23.0001V9.00005C2.99995 8.82525 3.04572 8.65349 3.13275 8.50189C3.21978 8.35029 3.34502 8.22415 3.496 8.13605L15.496 1.13605C15.649 1.04679 15.8229 0.999756 16 0.999756C16.1771 0.999756 16.351 1.04679 16.504 1.13605L28.504 8.13605L27.496 9.86405L16 3.15805L5 9.57405V22.4261L16 28.8431L27 22.4261V15.0001H29V23.0001C29.0001 23.1749 28.9543 23.3466 28.8673 23.4982C28.7802 23.6498 28.655 23.776 28.504 23.8641L16.504 30.8641C16.351 30.9533 16.1771 31.0002 16 31.0001Z" fill="black"/>
                    </svg>
                </div>
                <div class="gear-header-text">Асинхронное взаимодействие</div>
                <div class="gear-header-close gear-header-open" id="gear-btn-4">
                    <svg xmlns="https://www.w3.org/2000/svg" width="1.7vw" height="1.7vw" viewBox="0 0 32 32" fill="none">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M23.0547 9.85444C23.3057 9.60339 23.3057 9.19636 23.0547 8.94531C22.8036 8.69426 22.3966 8.69426 22.1455 8.94531L16.0005 15.0904L9.85592 8.9458C9.60486 8.69475 9.19783 8.69475 8.94678 8.9458C8.69573 9.19685 8.69573 9.60389 8.94678 9.85494L15.0913 15.9995L8.94622 22.1446C8.69517 22.3957 8.69517 22.8027 8.94622 23.0538C9.19727 23.3048 9.60431 23.3048 9.85536 23.0538L16.0005 16.9086L22.1461 23.0543C22.3972 23.3053 22.8042 23.3053 23.0552 23.0543C23.3063 22.8032 23.3063 22.3962 23.0552 22.1451L16.9096 15.9995L23.0547 9.85444Z" fill="#257379"/>
                    </svg>
                </div>
            </div>
            <div class="gear-content gear-close gear-none" id="gear-4">
                <div class="gear-text">            
                    При асинхронном типе взаимодействия происходи обращение к Apache Kafka и загрузка необходимых данных из БД с последующей отправкой соответствующему получателю. Для снижения нагрузки на сеть, контент запроса не скачивается, а передаётся по ссылке на файловый сервис. После получения подтверждения от получателя, в БД обновляется статус запроса, помечая его как ожидающий ответа.
                </div>
                <object width="73%" data="/assets/svg/Asinhronnoe.svg"></object>
            </div>
        </div>

        <a name="5"></a>
        <div class="gear-block">
            <div class="gear-block-header" (click)="open(5)">
                <div class="gear-header-icon">
                    <svg xmlns="https://www.w3.org/2000/svg" width="1.7vw" height="1.7vw" viewBox="0 0 32 32" fill="none">
                        <path d="M9 21H3C1.897 21 1 20.103 1 19V13C1 11.897 1.897 11 3 11H9C10.103 11 11 11.897 11 13V19C11 20.103 10.103 21 9 21ZM3 13V19H9V13H3ZM16 30C15.638 30 15.28 29.986 14.924 29.96C10.572 29.628 6.564 27.228 4.201 23.54L5.886 22.46C6.88999 24.0237 8.24186 25.3343 9.83603 26.2892C11.4302 27.2442 13.2235 27.8177 15.076 27.965C15.381 27.988 15.689 28 16 28V30ZM23 30L20.9 29C19.2 28.2 18 26.4 18 24.5V18H28V24.5C28 26.4 26.9 28.2 25.1 29L23 30ZM20 20V24.5C20 25.7 20.7 26.7 21.7 27.2L23 27.8L24.3 27.2C25.3 26.7 26 25.6 26 24.5V20H20ZM27.302 12C27.756 13.282 28 14.621 28 16H30C30 14.63 29.801 13.292 29.416 12H27.302ZM27 10H24C22.897 10 22 9.103 22 8V5C22 3.897 22.897 3 24 3H27C28.103 3 29 3.897 29 5V8C29 9.103 28.103 10 27 10ZM24 5V8H27V5H24ZM20 2.583C18.702 2.19552 17.3546 1.99913 16 2C13.6484 1.99516 11.3337 2.58484 9.27105 3.71425C7.20839 4.84365 5.4647 6.47614 4.202 8.46L5.886 9.54C7.34161 7.25285 9.53104 5.52804 12.0954 4.64831C14.6598 3.76857 17.4469 3.78609 20 4.698V2.583Z" fill="black"/>
                    </svg>
                </div>
                <div class="gear-header-text">синхронное взаимодействие</div>
                <div class="gear-header-close gear-header-open" id="gear-btn-5">
                    <svg xmlns="https://www.w3.org/2000/svg" width="1.7vw" height="1.7vw" viewBox="0 0 32 32" fill="none">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M23.0547 9.85444C23.3057 9.60339 23.3057 9.19636 23.0547 8.94531C22.8036 8.69426 22.3966 8.69426 22.1455 8.94531L16.0005 15.0904L9.85592 8.9458C9.60486 8.69475 9.19783 8.69475 8.94678 8.9458C8.69573 9.19685 8.69573 9.60389 8.94678 9.85494L15.0913 15.9995L8.94622 22.1446C8.69517 22.3957 8.69517 22.8027 8.94622 23.0538C9.19727 23.3048 9.60431 23.3048 9.85536 23.0538L16.0005 16.9086L22.1461 23.0543C22.3972 23.3053 22.8042 23.3053 23.0552 23.0543C23.3063 22.8032 23.3063 22.3962 23.0552 22.1451L16.9096 15.9995L23.0547 9.85444Z" fill="#257379"/>
                    </svg>
                </div>
            </div>
            <div class="gear-content gear-close gear-none" id="gear-5">
                <div class="gear-text">            
                    В случае синхронного взаимодействия обработка запроса происходит аналогично, но вместо подтверждения система получает бизнес-ответ (так же по ссылке на файловый сервис). Далее производится обновление записи в БД и отправка сообщения в Apache Kafka.
                </div>
                <object width="67%" data="/assets/svg/Sinhronnoe.svg"></object>
            </div>
        </div>

        <a name="6"></a>
        <div class="gear-block">
            <div class="gear-block-header" (click)="open(6)">
                <div class="gear-header-icon">
                    <svg xmlns="https://www.w3.org/2000/svg" width="1.7vw" height="1.7vw" viewBox="0 0 32 32" fill="none">
                        <path d="M9 21H3C1.897 21 1 20.103 1 19V13C1 11.897 1.897 11 3 11H9C10.103 11 11 11.897 11 13V19C11 20.103 10.103 21 9 21ZM3 13V19H9V13H3ZM16 30C15.638 30 15.28 29.986 14.924 29.96C10.572 29.628 6.564 27.228 4.201 23.54L5.886 22.46C6.88999 24.0238 8.24186 25.3343 9.83603 26.2892C11.4302 27.2442 13.2235 27.8177 15.076 27.965C15.381 27.988 15.689 28 16 28V30ZM23 30L20.9 29C19.2 28.2 18 26.4 18 24.5V18H28V24.5C28 26.4 26.9 28.2 25.1 29L23 30ZM20 20V24.5C20 25.7 20.7 26.7 21.7 27.2L23 27.8L24.3 27.2C25.3 26.7 26 25.6 26 24.5V20H20ZM27.302 12C27.756 13.282 28 14.621 28 16H30C30 14.63 29.801 13.292 29.416 12H27.302ZM27 10H24C22.897 10 22 9.10303 22 8.00003V5.00003C22 3.89703 22.897 3.00003 24 3.00003H27C28.103 3.00003 29 3.89703 29 5.00003V8.00003C29 9.10303 28.103 10 27 10ZM24 5.00003V8.00003H27V5.00003H24ZM20 2.58303C18.702 2.19555 17.3546 1.99916 16 2.00003C13.6484 1.99519 11.3337 2.58487 9.27105 3.71428C7.20839 4.84368 5.4647 6.47617 4.202 8.46003L5.886 9.54003C7.34161 7.25288 9.53104 5.52807 12.0954 4.64834C14.6598 3.7686 17.4469 3.78612 20 4.69803V2.58303Z" fill="black"/>
                    </svg>
                </div>
                <div class="gear-header-text">Открытая архитектура</div>
                <div class="gear-header-close gear-header-open" id="gear-btn-6">
                    <svg xmlns="https://www.w3.org/2000/svg" width="1.7vw" height="1.7vw" viewBox="0 0 32 32" fill="none">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M23.0547 9.85444C23.3057 9.60339 23.3057 9.19636 23.0547 8.94531C22.8036 8.69426 22.3966 8.69426 22.1455 8.94531L16.0005 15.0904L9.85592 8.9458C9.60486 8.69475 9.19783 8.69475 8.94678 8.9458C8.69573 9.19685 8.69573 9.60389 8.94678 9.85494L15.0913 15.9995L8.94622 22.1446C8.69517 22.3957 8.69517 22.8027 8.94622 23.0538C9.19727 23.3048 9.60431 23.3048 9.85536 23.0538L16.0005 16.9086L22.1461 23.0543C22.3972 23.3053 22.8042 23.3053 23.0552 23.0543C23.3063 22.8032 23.3063 22.3962 23.0552 22.1451L16.9096 15.9995L23.0547 9.85444Z" fill="#257379"/>
                    </svg>
                </div>
            </div>
            <div class="gear-content gear-close gear-none" id="gear-6">
                <div class="gear-text">            
                    Финальной частью обработки становится отправка ответа отправителю. Производится чтение из брокера сообщений, получение данных о запросе и ответе из PostgreSQL, и последующая отправка отправителю с обновлением записи в журнале запросов.
                    <br><br>
                    Архитектура сервиса позволяет добавлять практически любые механизмы промежуточной обработки данных добавлением необходимого компонента к адаптерно-транспортному модулю. Например, возможно реализовать функции маскирования (передача другой ИС только части бизнес-данных), выгрузки данных из ИС для дублирования и хранения в файловом сервисе (для безопасности хранения, что также, при необходимости, обеспечит возможность переноса данных в другую ИС) и других.
                </div>
                <object width="73%" data="/assets/svg/Otkritaia_arhitectura.svg"></object>
            </div>
        </div>

        <a name="7"></a>
        <div class="gear-block">
            <div class="gear-block-header" (click)="open(7)">
                <div class="gear-header-icon">
                    <svg xmlns="https://www.w3.org/2000/svg" width="1.7vw" height="1.7vw" viewBox="0 0 32 32" fill="none">
                        <path d="M9 21H3C1.897 21 1 20.103 1 19V13C1 11.897 1.897 11 3 11H9C10.103 11 11 11.897 11 13V19C11 20.103 10.103 21 9 21ZM3 13V19H9V13H3ZM16 30C15.638 30 15.28 29.986 14.924 29.96C10.572 29.628 6.564 27.228 4.201 23.54L5.886 22.46C6.88999 24.0238 8.24186 25.3343 9.83603 26.2892C11.4302 27.2442 13.2235 27.8177 15.076 27.965C15.381 27.988 15.689 28 16 28V30ZM23 30L20.9 29C19.2 28.2 18 26.4 18 24.5V18H28V24.5C28 26.4 26.9 28.2 25.1 29L23 30ZM20 20V24.5C20 25.7 20.7 26.7 21.7 27.2L23 27.8L24.3 27.2C25.3 26.7 26 25.6 26 24.5V20H20ZM27.302 12C27.756 13.282 28 14.621 28 16H30C30 14.63 29.801 13.292 29.416 12H27.302ZM27 10H24C22.897 10 22 9.10303 22 8.00003V5.00003C22 3.89703 22.897 3.00003 24 3.00003H27C28.103 3.00003 29 3.89703 29 5.00003V8.00003C29 9.10303 28.103 10 27 10ZM24 5.00003V8.00003H27V5.00003H24ZM20 2.58303C18.702 2.19555 17.3546 1.99916 16 2.00003C13.6484 1.99519 11.3337 2.58487 9.27105 3.71428C7.20839 4.84368 5.4647 6.47617 4.202 8.46003L5.886 9.54003C7.34161 7.25288 9.53104 5.52807 12.0954 4.64834C14.6598 3.7686 17.4469 3.78612 20 4.69803V2.58303Z" fill="black"/>
                    </svg>
                </div>
                <div class="gear-header-text">Локализация внешних сбоев и устранение их последствий</div>
                <div class="gear-header-close gear-header-open" id="gear-btn-7">
                    <svg xmlns="https://www.w3.org/2000/svg" width="1.7vw" height="1.7vw" viewBox="0 0 32 32" fill="none">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M23.0547 9.85444C23.3057 9.60339 23.3057 9.19636 23.0547 8.94531C22.8036 8.69426 22.3966 8.69426 22.1455 8.94531L16.0005 15.0904L9.85592 8.9458C9.60486 8.69475 9.19783 8.69475 8.94678 8.9458C8.69573 9.19685 8.69573 9.60389 8.94678 9.85494L15.0913 15.9995L8.94622 22.1446C8.69517 22.3957 8.69517 22.8027 8.94622 23.0538C9.19727 23.3048 9.60431 23.3048 9.85536 23.0538L16.0005 16.9086L22.1461 23.0543C22.3972 23.3053 22.8042 23.3053 23.0552 23.0543C23.3063 22.8032 23.3063 22.3962 23.0552 22.1451L16.9096 15.9995L23.0547 9.85444Z" fill="#257379"/>
                    </svg>
                </div>
            </div>
            <div class="gear-content gear-close gear-none" id="gear-7">
                <div class="gear-text">   
                    Наглядное обеспечение отказоустойчивости, снижение зависимости от существующих систем, упрощение обслуживания обеспечивается за счёт контура, замкнутого на КСМЭВ. При сбоях в одной или нескольких «точках отказа» в распределённом контуре происходят нарушения взаимодействий с множеством других систем, даже если они не связаны.
                </div>
                <img src="https://ksmev.vtcorp.ru/assets/img/gear-7-1.png" width="63%" alt="">
                <div class="gear-text">
                    Например, происходит длительное отсутствие сетевой связности с получателем или отправителем, приводящее к статусу "ошибка" у записи запроса. В этом случае из UI можно перезапустить неуспешный этап, без потери связанности с другими ИС.
                </div>
                <object width="85%" data="/assets/svg/response_post.svg"></object>
            </div>
        </div>

        <a name="8"></a>
        <div class="gear-block">
            <div class="gear-block-header" (click)="open(8)">
                <div class="gear-header-icon">
                    <svg xmlns="https://www.w3.org/2000/svg" width="1.7vw" height="1.7vw" viewBox="0 0 32 32" fill="none">
                        <path d="M9 21H3C1.897 21 1 20.103 1 19V13C1 11.897 1.897 11 3 11H9C10.103 11 11 11.897 11 13V19C11 20.103 10.103 21 9 21ZM3 13V19H9V13H3ZM16 30C15.638 30 15.28 29.986 14.924 29.96C10.572 29.628 6.564 27.228 4.201 23.54L5.886 22.46C6.88999 24.0238 8.24186 25.3343 9.83603 26.2892C11.4302 27.2442 13.2235 27.8177 15.076 27.965C15.381 27.988 15.689 28 16 28V30ZM23 30L20.9 29C19.2 28.2 18 26.4 18 24.5V18H28V24.5C28 26.4 26.9 28.2 25.1 29L23 30ZM20 20V24.5C20 25.7 20.7 26.7 21.7 27.2L23 27.8L24.3 27.2C25.3 26.7 26 25.6 26 24.5V20H20ZM27.302 12C27.756 13.282 28 14.621 28 16H30C30 14.63 29.801 13.292 29.416 12H27.302ZM27 10H24C22.897 10 22 9.10303 22 8.00003V5.00003C22 3.89703 22.897 3.00003 24 3.00003H27C28.103 3.00003 29 3.89703 29 5.00003V8.00003C29 9.10303 28.103 10 27 10ZM24 5.00003V8.00003H27V5.00003H24ZM20 2.58303C18.702 2.19555 17.3546 1.99916 16 2.00003C13.6484 1.99519 11.3337 2.58487 9.27105 3.71428C7.20839 4.84368 5.4647 6.47617 4.202 8.46003L5.886 9.54003C7.34161 7.25288 9.53104 5.52807 12.0954 4.64834C14.6598 3.7686 17.4469 3.78612 20 4.69803V2.58303Z" fill="black"/>
                    </svg>
                </div>
                <div class="gear-header-text">Оптимизация потока данных</div>
                <div class="gear-header-close gear-header-open" id="gear-btn-8">
                    <svg xmlns="https://www.w3.org/2000/svg" width="1.7vw" height="1.7vw" viewBox="0 0 32 32" fill="none">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M23.0547 9.85444C23.3057 9.60339 23.3057 9.19636 23.0547 8.94531C22.8036 8.69426 22.3966 8.69426 22.1455 8.94531L16.0005 15.0904L9.85592 8.9458C9.60486 8.69475 9.19783 8.69475 8.94678 8.9458C8.69573 9.19685 8.69573 9.60389 8.94678 9.85494L15.0913 15.9995L8.94622 22.1446C8.69517 22.3957 8.69517 22.8027 8.94622 23.0538C9.19727 23.3048 9.60431 23.3048 9.85536 23.0538L16.0005 16.9086L22.1461 23.0543C22.3972 23.3053 22.8042 23.3053 23.0552 23.0543C23.3063 22.8032 23.3063 22.3962 23.0552 22.1451L16.9096 15.9995L23.0547 9.85444Z" fill="#257379"/>
                    </svg>
                </div>
            </div>
            <div class="gear-content gear-close gear-none" id="gear-8">
                <div class="gear-text">   
                    Мы разгружаем конечные ИС, минимизируя нагрузку потока данных, за счёт того что файловый сервис является внешним хранилищем данных для ИС. Все взаимодействия осуществляются через file-api. <br><br>
    На примере наших предыдущих внедрений можем рассмотреть взаимодействие асинхронного типа, где получатель сам отправляет нам ответы.
                </div>
                <object width="73%" data="/assets/svg/Optimizazia_potoka.svg"></object>
            </div>
        </div>

        <a name="9"></a>
        <div class="gear-block">
            <div class="gear-block-header" (click)="open(9)">
                <div class="gear-header-icon">
                    <svg xmlns="https://www.w3.org/2000/svg" width="1.7vw" height="1.7vw" viewBox="0 0 32 32" fill="none">
                        <path d="M9 21H3C1.897 21 1 20.103 1 19V13C1 11.897 1.897 11 3 11H9C10.103 11 11 11.897 11 13V19C11 20.103 10.103 21 9 21ZM3 13V19H9V13H3ZM16 30C15.638 30 15.28 29.986 14.924 29.96C10.572 29.628 6.564 27.228 4.201 23.54L5.886 22.46C6.88999 24.0238 8.24186 25.3343 9.83603 26.2892C11.4302 27.2442 13.2235 27.8177 15.076 27.965C15.381 27.988 15.689 28 16 28V30ZM23 30L20.9 29C19.2 28.2 18 26.4 18 24.5V18H28V24.5C28 26.4 26.9 28.2 25.1 29L23 30ZM20 20V24.5C20 25.7 20.7 26.7 21.7 27.2L23 27.8L24.3 27.2C25.3 26.7 26 25.6 26 24.5V20H20ZM27.302 12C27.756 13.282 28 14.621 28 16H30C30 14.63 29.801 13.292 29.416 12H27.302ZM27 10H24C22.897 10 22 9.10303 22 8.00003V5.00003C22 3.89703 22.897 3.00003 24 3.00003H27C28.103 3.00003 29 3.89703 29 5.00003V8.00003C29 9.10303 28.103 10 27 10ZM24 5.00003V8.00003H27V5.00003H24ZM20 2.58303C18.702 2.19555 17.3546 1.99916 16 2.00003C13.6484 1.99519 11.3337 2.58487 9.27105 3.71428C7.20839 4.84368 5.4647 6.47617 4.202 8.46003L5.886 9.54003C7.34161 7.25288 9.53104 5.52807 12.0954 4.64834C14.6598 3.7686 17.4469 3.78612 20 4.69803V2.58303Z" fill="black"/>
                    </svg>
                </div>
                <div class="gear-header-text">Дополнительные возможности адаптеров</div>
                <div class="gear-header-close gear-header-open" id="gear-btn-9">
                    <svg xmlns="https://www.w3.org/2000/svg" width="1.7vw" height="1.7vw" viewBox="0 0 32 32" fill="none">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M23.0547 9.85444C23.3057 9.60339 23.3057 9.19636 23.0547 8.94531C22.8036 8.69426 22.3966 8.69426 22.1455 8.94531L16.0005 15.0904L9.85592 8.9458C9.60486 8.69475 9.19783 8.69475 8.94678 8.9458C8.69573 9.19685 8.69573 9.60389 8.94678 9.85494L15.0913 15.9995L8.94622 22.1446C8.69517 22.3957 8.69517 22.8027 8.94622 23.0538C9.19727 23.3048 9.60431 23.3048 9.85536 23.0538L16.0005 16.9086L22.1461 23.0543C22.3972 23.3053 22.8042 23.3053 23.0552 23.0543C23.3063 22.8032 23.3063 22.3962 23.0552 22.1451L16.9096 15.9995L23.0547 9.85444Z" fill="#257379"/>
                    </svg>
                </div>
            </div>
            <div class="gear-content gear-close gear-none" id="gear-9">
                <div class="gear-text">   
                    Как пример расширенного функционала адаптеров, можно рассмотреть возможность синхронного взаимодействия за счёт включения дополнительных шагов (например для аутентификации при отправке запроса).
                </div>
                <object width="100%" data="/assets/svg/Dopolnitelnie_vozmojnosti.svg"></object>
            </div>
        </div>

        <a name="10"></a>
        <div class="gear-block">
            <div class="gear-block-header" (click)="open(10)">
                <div class="gear-header-icon">
                    <svg xmlns="https://www.w3.org/2000/svg" width="1.7vw" height="1.7vw" viewBox="0 0 32 32" fill="none">
                        <path d="M9 21H3C1.897 21 1 20.103 1 19V13C1 11.897 1.897 11 3 11H9C10.103 11 11 11.897 11 13V19C11 20.103 10.103 21 9 21ZM3 13V19H9V13H3ZM16 30C15.638 30 15.28 29.986 14.924 29.96C10.572 29.628 6.564 27.228 4.201 23.54L5.886 22.46C6.88999 24.0238 8.24186 25.3343 9.83603 26.2892C11.4302 27.2442 13.2235 27.8177 15.076 27.965C15.381 27.988 15.689 28 16 28V30ZM23 30L20.9 29C19.2 28.2 18 26.4 18 24.5V18H28V24.5C28 26.4 26.9 28.2 25.1 29L23 30ZM20 20V24.5C20 25.7 20.7 26.7 21.7 27.2L23 27.8L24.3 27.2C25.3 26.7 26 25.6 26 24.5V20H20ZM27.302 12C27.756 13.282 28 14.621 28 16H30C30 14.63 29.801 13.292 29.416 12H27.302ZM27 10H24C22.897 10 22 9.10303 22 8.00003V5.00003C22 3.89703 22.897 3.00003 24 3.00003H27C28.103 3.00003 29 3.89703 29 5.00003V8.00003C29 9.10303 28.103 10 27 10ZM24 5.00003V8.00003H27V5.00003H24ZM20 2.58303C18.702 2.19555 17.3546 1.99916 16 2.00003C13.6484 1.99519 11.3337 2.58487 9.27105 3.71428C7.20839 4.84368 5.4647 6.47617 4.202 8.46003L5.886 9.54003C7.34161 7.25288 9.53104 5.52807 12.0954 4.64834C14.6598 3.7686 17.4469 3.78612 20 4.69803V2.58303Z" fill="black"/>
                    </svg>
                </div>
                <div class="gear-header-text">Гибкий файловый сервис</div>
                <div class="gear-header-close gear-header-open" id="gear-btn-10">
                    <svg xmlns="https://www.w3.org/2000/svg" width="1.7vw" height="1.7vw" viewBox="0 0 32 32" fill="none">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M23.0547 9.85444C23.3057 9.60339 23.3057 9.19636 23.0547 8.94531C22.8036 8.69426 22.3966 8.69426 22.1455 8.94531L16.0005 15.0904L9.85592 8.9458C9.60486 8.69475 9.19783 8.69475 8.94678 8.9458C8.69573 9.19685 8.69573 9.60389 8.94678 9.85494L15.0913 15.9995L8.94622 22.1446C8.69517 22.3957 8.69517 22.8027 8.94622 23.0538C9.19727 23.3048 9.60431 23.3048 9.85536 23.0538L16.0005 16.9086L22.1461 23.0543C22.3972 23.3053 22.8042 23.3053 23.0552 23.0543C23.3063 22.8032 23.3063 22.3962 23.0552 22.1451L16.9096 15.9995L23.0547 9.85444Z" fill="#257379"/>
                    </svg>
                </div>
            </div>
            <div class="gear-content gear-close gear-none" id="gear-10">
                <div class="gear-text">   
                    На данной схеме можно наблюдать работу по загрузке в MinIO с помощью файлового сервиса. Остальные операции (получение файла, удаление файла, загрузка нескольких файлов в один zip-архив) выполняется тем же способом. <br> <br>
    Файловый сервис предназначен для абстрагирования работы с конкретной имплементацией. Как и ранее в презентации, здесь так же указан MinIO, однако архитектура позволяет простым способом сменить MinIO на любое другое S3 хранилище или, с некоторыми доработками, на любое другое хранилище (например S3 от облачного провайдера или какое-то своё объектное хранилище).
                </div>
                <object width="75%" data="/assets/svg/Failovii_serves.svg"></object>
            </div>
        </div>

        <a name="11"></a>
        <div class="gear-block">
            <div class="gear-block-header" (click)="open(11)">
                <div class="gear-header-icon">
                    <svg xmlns="https://www.w3.org/2000/svg" width="1.7vw" height="1.7vw" viewBox="0 0 32 32" fill="none">
                        <path d="M9 21H3C1.897 21 1 20.103 1 19V13C1 11.897 1.897 11 3 11H9C10.103 11 11 11.897 11 13V19C11 20.103 10.103 21 9 21ZM3 13V19H9V13H3ZM16 30C15.638 30 15.28 29.986 14.924 29.96C10.572 29.628 6.564 27.228 4.201 23.54L5.886 22.46C6.88999 24.0238 8.24186 25.3343 9.83603 26.2892C11.4302 27.2442 13.2235 27.8177 15.076 27.965C15.381 27.988 15.689 28 16 28V30ZM23 30L20.9 29C19.2 28.2 18 26.4 18 24.5V18H28V24.5C28 26.4 26.9 28.2 25.1 29L23 30ZM20 20V24.5C20 25.7 20.7 26.7 21.7 27.2L23 27.8L24.3 27.2C25.3 26.7 26 25.6 26 24.5V20H20ZM27.302 12C27.756 13.282 28 14.621 28 16H30C30 14.63 29.801 13.292 29.416 12H27.302ZM27 10H24C22.897 10 22 9.10303 22 8.00003V5.00003C22 3.89703 22.897 3.00003 24 3.00003H27C28.103 3.00003 29 3.89703 29 5.00003V8.00003C29 9.10303 28.103 10 27 10ZM24 5.00003V8.00003H27V5.00003H24ZM20 2.58303C18.702 2.19555 17.3546 1.99916 16 2.00003C13.6484 1.99519 11.3337 2.58487 9.27105 3.71428C7.20839 4.84368 5.4647 6.47617 4.202 8.46003L5.886 9.54003C7.34161 7.25288 9.53104 5.52807 12.0954 4.64834C14.6598 3.7686 17.4469 3.78612 20 4.69803V2.58303Z" fill="black"/>
                    </svg>
                </div>
                <div class="gear-header-text">Инструменты мониторинга</div>
                <div class="gear-header-close gear-header-open" id="gear-btn-11">
                    <svg xmlns="https://www.w3.org/2000/svg" width="1.7vw" height="1.7vw" viewBox="0 0 32 32" fill="none">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M23.0547 9.85444C23.3057 9.60339 23.3057 9.19636 23.0547 8.94531C22.8036 8.69426 22.3966 8.69426 22.1455 8.94531L16.0005 15.0904L9.85592 8.9458C9.60486 8.69475 9.19783 8.69475 8.94678 8.9458C8.69573 9.19685 8.69573 9.60389 8.94678 9.85494L15.0913 15.9995L8.94622 22.1446C8.69517 22.3957 8.69517 22.8027 8.94622 23.0538C9.19727 23.3048 9.60431 23.3048 9.85536 23.0538L16.0005 16.9086L22.1461 23.0543C22.3972 23.3053 22.8042 23.3053 23.0552 23.0543C23.3063 22.8032 23.3063 22.3962 23.0552 22.1451L16.9096 15.9995L23.0547 9.85444Z" fill="#257379"/>
                    </svg>
                </div>
            </div>
            <div class="gear-content gear-close gear-none" id="gear-11">
                <div class="gear-text">   
                    Инструменты мониторинга предназначены для получения технической информации по состоянию инфраструктуры и сервисов программного комплекса КСМЭВ (Prometeus производит сбор метрик, Grafana отображает алерты на основе метрик, Zipkin производит трейсинг взаимодействий).
                </div>
                <img src="https://ksmev.vtcorp.ru/assets/img/gear-11-1.png" width="62%" alt="" style="vertical-align: top;">
                <img src="https://ksmev.vtcorp.ru/assets/img/gear-11-2.png" width="35%" alt="" style="float: right;">
                <img src="https://ksmev.vtcorp.ru/assets/img/gear-11-3.png" width="62%" alt="" style="margin-top: 2.5%;">
            </div>
        </div>

        <a name="12"></a>
        <div class="gear-block">
            <div class="gear-block-header" (click)="open(12)">
                <div class="gear-header-icon">
                    <svg xmlns="https://www.w3.org/2000/svg" width="1.7vw" height="1.7vw" viewBox="0 0 32 32" fill="none">
                        <path d="M9 21H3C1.897 21 1 20.103 1 19V13C1 11.897 1.897 11 3 11H9C10.103 11 11 11.897 11 13V19C11 20.103 10.103 21 9 21ZM3 13V19H9V13H3ZM16 30C15.638 30 15.28 29.986 14.924 29.96C10.572 29.628 6.564 27.228 4.201 23.54L5.886 22.46C6.88999 24.0238 8.24186 25.3343 9.83603 26.2892C11.4302 27.2442 13.2235 27.8177 15.076 27.965C15.381 27.988 15.689 28 16 28V30ZM23 30L20.9 29C19.2 28.2 18 26.4 18 24.5V18H28V24.5C28 26.4 26.9 28.2 25.1 29L23 30ZM20 20V24.5C20 25.7 20.7 26.7 21.7 27.2L23 27.8L24.3 27.2C25.3 26.7 26 25.6 26 24.5V20H20ZM27.302 12C27.756 13.282 28 14.621 28 16H30C30 14.63 29.801 13.292 29.416 12H27.302ZM27 10H24C22.897 10 22 9.10303 22 8.00003V5.00003C22 3.89703 22.897 3.00003 24 3.00003H27C28.103 3.00003 29 3.89703 29 5.00003V8.00003C29 9.10303 28.103 10 27 10ZM24 5.00003V8.00003H27V5.00003H24ZM20 2.58303C18.702 2.19555 17.3546 1.99916 16 2.00003C13.6484 1.99519 11.3337 2.58487 9.27105 3.71428C7.20839 4.84368 5.4647 6.47617 4.202 8.46003L5.886 9.54003C7.34161 7.25288 9.53104 5.52807 12.0954 4.64834C14.6598 3.7686 17.4469 3.78612 20 4.69803V2.58303Z" fill="black"/>
                    </svg>
                </div>
                <div class="gear-header-text">Инструменты журналирования</div>
                <div class="gear-header-close gear-header-open" id="gear-btn-12">
                    <svg xmlns="https://www.w3.org/2000/svg" width="1.7vw" height="1.7vw" viewBox="0 0 32 32" fill="none">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M23.0547 9.85444C23.3057 9.60339 23.3057 9.19636 23.0547 8.94531C22.8036 8.69426 22.3966 8.69426 22.1455 8.94531L16.0005 15.0904L9.85592 8.9458C9.60486 8.69475 9.19783 8.69475 8.94678 8.9458C8.69573 9.19685 8.69573 9.60389 8.94678 9.85494L15.0913 15.9995L8.94622 22.1446C8.69517 22.3957 8.69517 22.8027 8.94622 23.0538C9.19727 23.3048 9.60431 23.3048 9.85536 23.0538L16.0005 16.9086L22.1461 23.0543C22.3972 23.3053 22.8042 23.3053 23.0552 23.0543C23.3063 22.8032 23.3063 22.3962 23.0552 22.1451L16.9096 15.9995L23.0547 9.85444Z" fill="#257379"/>
                    </svg>
                </div>
            </div>
            <div class="gear-content gear-close gear-none" id="gear-12">
                <div class="gear-text">   
                    Инструменты журналирования обеспечивают сбор и хранение логов в структурированном виде с возможностью полнотекстового поиска (Logstash принимает логи, Kibana их обрабатывает, Elasticsearch хранит).
                </div>
                <img src="https://ksmev.vtcorp.ru/assets/img/gear-12.png" width="100%" alt="">
            </div>
        </div>

        <a name="13"></a>
        <div class="gear-block">
            <div class="gear-block-header" (click)="open(13)">
                <div class="gear-header-icon">
                    <svg xmlns="https://www.w3.org/2000/svg" width="1.7vw" height="1.7vw" viewBox="0 0 32 32" fill="none">
                        <path d="M9 21H3C1.897 21 1 20.103 1 19V13C1 11.897 1.897 11 3 11H9C10.103 11 11 11.897 11 13V19C11 20.103 10.103 21 9 21ZM3 13V19H9V13H3ZM16 30C15.638 30 15.28 29.986 14.924 29.96C10.572 29.628 6.564 27.228 4.201 23.54L5.886 22.46C6.88999 24.0238 8.24186 25.3343 9.83603 26.2892C11.4302 27.2442 13.2235 27.8177 15.076 27.965C15.381 27.988 15.689 28 16 28V30ZM23 30L20.9 29C19.2 28.2 18 26.4 18 24.5V18H28V24.5C28 26.4 26.9 28.2 25.1 29L23 30ZM20 20V24.5C20 25.7 20.7 26.7 21.7 27.2L23 27.8L24.3 27.2C25.3 26.7 26 25.6 26 24.5V20H20ZM27.302 12C27.756 13.282 28 14.621 28 16H30C30 14.63 29.801 13.292 29.416 12H27.302ZM27 10H24C22.897 10 22 9.10303 22 8.00003V5.00003C22 3.89703 22.897 3.00003 24 3.00003H27C28.103 3.00003 29 3.89703 29 5.00003V8.00003C29 9.10303 28.103 10 27 10ZM24 5.00003V8.00003H27V5.00003H24ZM20 2.58303C18.702 2.19555 17.3546 1.99916 16 2.00003C13.6484 1.99519 11.3337 2.58487 9.27105 3.71428C7.20839 4.84368 5.4647 6.47617 4.202 8.46003L5.886 9.54003C7.34161 7.25288 9.53104 5.52807 12.0954 4.64834C14.6598 3.7686 17.4469 3.78612 20 4.69803V2.58303Z" fill="black"/>
                    </svg>
                </div>
                <div class="gear-header-text">Современные архитектурные решения</div>
                <div class="gear-header-close gear-header-open" id="gear-btn-13">
                    <svg xmlns="https://www.w3.org/2000/svg" width="1.7vw" height="1.7vw" viewBox="0 0 32 32" fill="none">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M23.0547 9.85444C23.3057 9.60339 23.3057 9.19636 23.0547 8.94531C22.8036 8.69426 22.3966 8.69426 22.1455 8.94531L16.0005 15.0904L9.85592 8.9458C9.60486 8.69475 9.19783 8.69475 8.94678 8.9458C8.69573 9.19685 8.69573 9.60389 8.94678 9.85494L15.0913 15.9995L8.94622 22.1446C8.69517 22.3957 8.69517 22.8027 8.94622 23.0538C9.19727 23.3048 9.60431 23.3048 9.85536 23.0538L16.0005 16.9086L22.1461 23.0543C22.3972 23.3053 22.8042 23.3053 23.0552 23.0543C23.3063 22.8032 23.3063 22.3962 23.0552 22.1451L16.9096 15.9995L23.0547 9.85444Z" fill="#257379"/>
                    </svg>
                </div>
            </div>
            <div class="gear-content gear-close gear-none" id="gear-13">
                <img src="https://ksmev.vtcorp.ru/assets/img/gear-13-1.png" style="margin-top: 1.5vw;" width="10%" alt="">
                <div class="gear-text" >   
                    Vault является не только лучшим современным и безопасным хранилищем секретов, но позволяет удобно управлять конфигурацией. А именно, обеспечивает быстрый доступ к конфигурационным файлам без необходимости заходить на виртуальную машину, обеспечивает версионность (можно вернуться к определённой версии конфигурационного файла).
                </div>
                <img src="https://ksmev.vtcorp.ru/assets/img/gear-13-2.png" width="10%" alt="">
                <div class="gear-text">   
                    Компонент синхронизации (Redis) используется для распределённых блокировок и лимитирования количества запросов в ИС в единицу времени. Позволяет легко горизонтально масштабировать количество подов с нашими сервисами без потери синхронности взаимодействия (с любым количеством подов мы не выходим за рейт лимиты и порядок выстраивания очереди запросов не сбивается).
                </div>
            </div>
        </div>

        <a name="14"></a>
        <div class="gear-block">
            <div class="gear-block-header" (click)="open(14)">
                <div class="gear-header-icon">
                    <svg xmlns="https://www.w3.org/2000/svg" width="1.7vw" height="1.7vw" viewBox="0 0 32 32" fill="none">
                        <path d="M9 21H3C1.897 21 1 20.103 1 19V13C1 11.897 1.897 11 3 11H9C10.103 11 11 11.897 11 13V19C11 20.103 10.103 21 9 21ZM3 13V19H9V13H3ZM16 30C15.638 30 15.28 29.986 14.924 29.96C10.572 29.628 6.564 27.228 4.201 23.54L5.886 22.46C6.88999 24.0238 8.24186 25.3343 9.83603 26.2892C11.4302 27.2442 13.2235 27.8177 15.076 27.965C15.381 27.988 15.689 28 16 28V30ZM23 30L20.9 29C19.2 28.2 18 26.4 18 24.5V18H28V24.5C28 26.4 26.9 28.2 25.1 29L23 30ZM20 20V24.5C20 25.7 20.7 26.7 21.7 27.2L23 27.8L24.3 27.2C25.3 26.7 26 25.6 26 24.5V20H20ZM27.302 12C27.756 13.282 28 14.621 28 16H30C30 14.63 29.801 13.292 29.416 12H27.302ZM27 10H24C22.897 10 22 9.10303 22 8.00003V5.00003C22 3.89703 22.897 3.00003 24 3.00003H27C28.103 3.00003 29 3.89703 29 5.00003V8.00003C29 9.10303 28.103 10 27 10ZM24 5.00003V8.00003H27V5.00003H24ZM20 2.58303C18.702 2.19555 17.3546 1.99916 16 2.00003C13.6484 1.99519 11.3337 2.58487 9.27105 3.71428C7.20839 4.84368 5.4647 6.47617 4.202 8.46003L5.886 9.54003C7.34161 7.25288 9.53104 5.52807 12.0954 4.64834C14.6598 3.7686 17.4469 3.78612 20 4.69803V2.58303Z" fill="black"/>
                    </svg>
                </div>
                <div class="gear-header-text">Архитектурная схема</div>
                <div class="gear-header-close gear-header-open" id="gear-btn-14">
                    <svg xmlns="https://www.w3.org/2000/svg" width="1.7vw" height="1.7vw" viewBox="0 0 32 32" fill="none">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M23.0547 9.85444C23.3057 9.60339 23.3057 9.19636 23.0547 8.94531C22.8036 8.69426 22.3966 8.69426 22.1455 8.94531L16.0005 15.0904L9.85592 8.9458C9.60486 8.69475 9.19783 8.69475 8.94678 8.9458C8.69573 9.19685 8.69573 9.60389 8.94678 9.85494L15.0913 15.9995L8.94622 22.1446C8.69517 22.3957 8.69517 22.8027 8.94622 23.0538C9.19727 23.3048 9.60431 23.3048 9.85536 23.0538L16.0005 16.9086L22.1461 23.0543C22.3972 23.3053 22.8042 23.3053 23.0552 23.0543C23.3063 22.8032 23.3063 22.3962 23.0552 22.1451L16.9096 15.9995L23.0547 9.85444Z" fill="#257379"/>
                    </svg>
                </div>
            </div>
            <div class="gear-content gear-close gear-none" id="gear-14">
                <object class="arch-svg" data="/assets/svg/architecture.svg"></object>
            </div>
        </div>
    </div>
</div>

<app-footer></app-footer>