<div class="login" *ngIf="!isAdmin">
    <div class="login-header">Вход в панель</div>
    <input [(ngModel)]="password" class="password" type="password" placeholder="Пароль">
    <button id="btn" class="login-btn" (click)="logIn()">Войти</button>
</div>

<div *ngIf="isAdmin && isMonths" class="stats">
    <div class="stats-header">Статистика</div>
    <div class="label-stats">
        <div class="month-stat" *ngFor="let month of months; index as i;">
            <div class="imagine" style='height: {{month / maxByMonth * 33}}vh'><div class="imagine-count">{{month}}</div></div>
            <div class="value" (click)="getVisitsPerDay(i)">{{visits[i].month}}</div>
        </div>
    </div>
</div>

<div *ngIf="isAdmin && !isMonths" class="stats">
    <div class="stats-header">Статистика за {{visits[selectedMonth].month}}</div>
    <svg (click)="close()" class="close" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 33 33" fill="none">
        <path d="M18.4387 16.5L24.3512 10.6013C24.6101 10.3423 24.7555 9.99118 24.7555 9.62501C24.7555 9.25885 24.6101 8.90768 24.3512 8.64876C24.0922 8.38984 23.7411 8.24438 23.3749 8.24438C23.0088 8.24438 22.6576 8.38984 22.3987 8.64876L16.4999 14.5613L10.6012 8.64876C10.3422 8.38984 9.99108 8.24438 9.62492 8.24438C9.25875 8.24438 8.90758 8.38984 8.64867 8.64876C8.38975 8.90768 8.24429 9.25885 8.24429 9.62501C8.24429 9.99118 8.38975 10.3423 8.64867 10.6013L14.5612 16.5L8.64867 22.3988C8.51979 22.5266 8.4175 22.6787 8.34769 22.8462C8.27788 23.0138 8.24194 23.1935 8.24194 23.375C8.24194 23.5565 8.27788 23.7362 8.34769 23.9038C8.4175 24.0714 8.51979 24.2234 8.64867 24.3513C8.77649 24.4801 8.92857 24.5824 9.09612 24.6522C9.26368 24.722 9.4434 24.758 9.62492 24.758C9.80643 24.758 9.98615 24.722 10.1537 24.6522C10.3213 24.5824 10.4733 24.4801 10.6012 24.3513L16.4999 18.4388L22.3987 24.3513C22.5265 24.4801 22.6786 24.5824 22.8461 24.6522C23.0137 24.722 23.1934 24.758 23.3749 24.758C23.5564 24.758 23.7362 24.722 23.9037 24.6522C24.0713 24.5824 24.2233 24.4801 24.3512 24.3513C24.48 24.2234 24.5823 24.0714 24.6521 23.9038C24.722 23.7362 24.7579 23.5565 24.7579 23.375C24.7579 23.1935 24.722 23.0138 24.6521 22.8462C24.5823 22.6787 24.48 22.5266 24.3512 22.3988L18.4387 16.5Z" fill="#798B8D"/>
    </svg>

    <div class="label-stats">
        <div class="day-stat" *ngFor="let visit of visits[selectedMonth].visits; index as i;">
            <div class="imagine" style='height: {{visit / maxByDay * 33}}vh'><div class="imagine-count">{{visit}}</div></div>
            <div>{{i + 1}}</div>
        </div>
    </div>
</div>

<div class="days">

</div>
