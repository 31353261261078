import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MainComponent } from './components/main/main.component';
import { GearComponent } from './components/gear/gear.component';
import { FeedbackComponent } from './components/feedback/feedback.component';
import { VisitsComponent } from './components/visits/visits.component';

const routes: Routes = [
  { path: '', component: MainComponent },
  { path: 'mechanism', component: GearComponent },
  { path: 'stats', component:VisitsComponent },
  { path: '**', redirectTo: '', pathMatch: 'full' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }